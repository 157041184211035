import React, { useState, useEffect } from 'react';
import { useRouter } from 'next/navigation';
import { useAuth } from '@clerk/nextjs';
import { processFrame } from '../api/process';
import PopupForm from './PopupForm';
import { ThemeProvider } from '@mui/material/styles';
import theme from '../theme/theme';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

const HighlightForm: React.FC = () => {
  const [link, setLink] = useState<string>('');
  const [isPopupVisible, setIsPopupVisible] = useState<boolean>(false);
  const [imageData, setImageData] = useState<any>(null);
  const [faceBox, setFaceBox] = useState<any>(null);
  const [gameplayBox, setGameplayBox] = useState<any>(null);
  const [isFacecam, setIsFacecam] = useState<boolean>(false);
  const [isFullscreen, setIsFullscreen] = useState<boolean>(true);
  const [videoType, setVideoType] = useState<string>('vod');
  const [textColor, setTextColor] = useState<string>('yellow');
  const [censorSubtitles, setCensorSubtitles] = useState<boolean>(true);
  const router = useRouter();
  const { isSignedIn } = useAuth();
  const [isLandingPage, setIsLandingPage] = useState<boolean>(false);
  const [currentPath, setCurrentPath] = useState<string>('/');
  const [fontName, setFontName] = useState<string>("CheGuevaraBarry-Brown");

  useEffect(() => {
    const path = window.location.pathname;
    setIsLandingPage(path === '/');
    setCurrentPath(path);
    console.log('Current path:', path);
  }, []);

  const handleGenerate = async () => {
    const currentPath = window.location.pathname;
  
    const isValidLink = (link: string): 'vod' | 'clip' | null => {
      const vodRegex = /^https?:\/\/(?:www\.)?twitch\.tv\/videos\/(\d+)(?:\?.*)?$/;
      const clipRegex = /^https?:\/\/(?:www\.)?twitch\.tv\/\w+\/clip\/([A-Za-z0-9_-]+)(?:\?.*)?$/;
    
      if ((currentPath === '/' || currentPath === '/streams') && vodRegex.test(link)) {
        return 'vod';
      } else if (currentPath === '/clips' && clipRegex.test(link)) {
        return null;
      }
      return null;
    };
  
    const linkType = isValidLink(link);
    
    if (!linkType) {
      alert(`Please enter a valid Twitch ${currentPath === '/clips' ? 'clip' : 'VOD/video'} link.`);
      return;
    }
  
    setVideoType(linkType);
    setIsPopupVisible(true);

    try {
      const frameData = await processFrame(link, linkType);

      if (frameData.result) {
        setImageData(frameData.result);
        setIsFacecam(frameData.result.face_coordinates !== null);
      }
    } catch (error) {
      console.error('Error generating frame:', error);
      setIsPopupVisible(false);
    }
  };

  const handleFaceBoxChange = (newBox: any) => {
    setFaceBox(newBox);
  };

  const handleGameplayBoxChange = (newBox: any) => {
    setGameplayBox(newBox);
  };

  const handleFacecamToggle = (newFacecamState: boolean) => {
    setIsFacecam(newFacecamState);
    setIsFullscreen(!newFacecamState);
  };

  const handleSubmit = (faceBox: number[], gameplayBox: number[], isFacecam: boolean, textColor: string, censorSubtitles: boolean, fontName: string) => {
    if (isLandingPage && !isSignedIn) {
      localStorage.setItem('pendingHighlight', JSON.stringify({
        link,
        faceBox,
        gameplayBox,
        isFacecam,
        videoType,
        imageData,
        isFullscreen,
        numOfHighlights: 20,
        textColor,
        censorSubtitles,
        fontName
      }));
      
      router.push('/sign-in');
      return;
    }

    submitHighlight(faceBox, gameplayBox, isFacecam, textColor, censorSubtitles, fontName);
  };

  const submitHighlight = (faceBox: number[], gameplayBox: number[], isFacecam: boolean, textColor: string, censorSubtitles: boolean, fontName: string) => {
    const faceCoordinates = JSON.stringify(faceBox?.map(Math.floor) || []);
    const gameplayCoordinates = JSON.stringify(gameplayBox?.map(Math.floor) || []);
    const frameUrl = imageData?.frame;
    const numOfHighlights = 20; 

    let query = `link=${encodeURIComponent(link)}&isFacecam=${!isFacecam}&faceCoordinates=${encodeURIComponent(faceCoordinates)}&gameplayCoordinates=${encodeURIComponent(gameplayCoordinates)}&videoType=${encodeURIComponent(videoType)}&textColor=${encodeURIComponent(textColor)}&censorSubtitles=${censorSubtitles}&fontName=${encodeURIComponent(fontName)}`;

    if (videoType === 'vod') {
      query += `&numOfHighlights=${numOfHighlights}&frameUrl=${encodeURIComponent(frameUrl || '')}`;
    }

    router.push(`/loading?${query}`);
  };

  useEffect(() => {
    if (isSignedIn) {
      const pendingHighlight = localStorage.getItem('pendingHighlight');
      if (pendingHighlight) {
        const {
          link,
          faceBox,
          gameplayBox,
          isFacecam,
          videoType,
          imageData,
          isFullscreen,
          numOfHighlights,
          textColor,
          censorSubtitles
        } = JSON.parse(pendingHighlight);

        setLink(link);
        setFaceBox(faceBox);
        setGameplayBox(gameplayBox);
        setIsFacecam(isFacecam);
        setVideoType(videoType);
        setImageData(imageData);
        setIsFullscreen(isFullscreen);
        setTextColor(textColor);
        setCensorSubtitles(censorSubtitles);

        localStorage.removeItem('pendingHighlight');
        
        submitHighlight(faceBox, gameplayBox, isFacecam, textColor, censorSubtitles, fontName);
      }
    }
  }, [isSignedIn, router]);

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          width: '90%',
        }}
      >
        <TextField
          label={
            currentPath === '/clips'
              ? 'Please enter a valid Twitch clip link.'
              : 'Please enter a valid Twitch VOD link.'
          }
          variant="outlined"
          fullWidth
          value={link}
          onChange={(e) => setLink(e.target.value)}
          type="url"
          required
          sx={{
            flexGrow: 1,
            mr: 1.5,
            '& .MuiInputBase-root': {
              height: '56px',
              display: 'flex',
              alignItems: 'center',
            },
            '& .MuiInputBase-input': {
              color: '#ffffff',
              height: '100%',
              padding: '0 14px',
              boxSizing: 'border-box',
            },
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: 'rgba(255, 255, 255, 0.23)',
              },
              '&:hover fieldset': {
                borderColor: 'rgba(255, 255, 255, 0.5)',
              },
              '&.Mui-focused fieldset': {
                borderColor: '#ffffff',
              },
            },
            '& .MuiInputLabel-root': {
              color: 'rgba(255, 255, 255, 0.7)',
              transform: 'translate(14px, -6px) scale(0.75)',
              '&[data-shrink="false"]': {
                transform: 'translate(14px, 16px) scale(1)',
              },
            },
          }}
        />
        <Box
          sx={{
            height: '56px',
            display: 'flex',
            alignItems: 'flex-end',
          }}
        >
          <Button
            onClick={handleGenerate}
            variant="outlined"
            sx={{
              height: '100%',
              minWidth: '110px',
              backgroundColor: 'rgba(255, 255, 255, 0.00)',
              color: 'rgba(255, 255, 255, 0.7)',
              borderColor: 'rgba(255, 255, 255, 0.23)',
              '&:hover': {
                backgroundColor: 'rgba(255, 255, 255, 0.12)',
                borderColor: '#ffffff',
              },
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            GENERATE
          </Button>
        </Box>
      </Box>
      <PopupForm
        isVisible={isPopupVisible}
        onClose={() => setIsPopupVisible(false)}
        imageData={imageData}
        isFacecam={isFacecam}
        onFacecamToggle={handleFacecamToggle}
        onFaceBoxChange={handleFaceBoxChange}
        onGameplayBoxChange={handleGameplayBoxChange}
        handleSubmit={handleSubmit}
      />
    </ThemeProvider>
  );
};

export default HighlightForm;