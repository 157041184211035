import React from 'react';

const SocialProof: React.FC = () => {
  const clients = [
    { 
      name: 'TheRealTay614', 
      url: 'https://www.twitch.tv/therealtay614',
      image: 'https://i.ibb.co/HBXDFLN/therealtay.png'
    },
    { 
      name: 'TheOriginalMalik', 
      url: 'https://www.twitch.tv/theoriginalmalik_',
      image: 'https://i.ibb.co/8KXDRjd/malik.jpg'
    },
    { 
      name: 'UnderratedGoat', 
      url: 'https://www.twitch.tv/underratedgoat_/videos',
      image: 'https://i.ibb.co/yRGCsQW/underratedgoat.jpg'
    }
  ];

  const highlights = [
    { 
      url: 'https://storage.googleapis.com/saved_media_assets/SPMedia1.mp4',
      tiktokUrl: 'https://www.tiktok.com/@therealtay614/video/7401208897459014954?is_from_webapp=1&sender_device=pc&web_id=7339386491338229290'
    },
    { 
      url: 'https://storage.googleapis.com/saved_media_assets/SPMedia3.mp4',
      tiktokUrl: 'https://www.tiktok.com/@originalmalik_/video/7393725902682721582?is_from_webapp=1&sender_device=pc&web_id=7339386491338229290'
    },
    { 
      url: 'https://storage.googleapis.com/saved_media_assets/SPMedia2.mp4',
      tiktokUrl: 'https://www.tiktok.com/@underratedgoat._/video/7390429198701038879?is_from_webapp=1&sender_device=pc&web_id=7339386491338229290'
    }
  ];

  return (
    <section className="py-12 overflow-hidden">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-3xl font-bold text-white text-center mb-8">Trusted by Streamers</h2>
        <div className="flex justify-center">
          <div className="overflow-x-auto pb-4 mb-16 scrollbar-hide max-w-full">
            <div className="flex space-x-8 md:space-x-16 px-4">
              {clients.map((client, index) => (
                <a
                  key={index}
                  href={client.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex flex-col items-center group flex-shrink-0"
                >
                  <div className="w-20 h-20 md:w-24 md:h-24 rounded-full overflow-hidden mb-4 border-2 border-transparent group-hover:border-blue-400 transition duration-300">
                    <img src={client.image} alt={client.name} className="w-full h-full object-cover" />
                  </div>
                  <span className="text-blue-400 group-hover:text-blue-300 transition duration-300 text-center">
                    {client.name}
                  </span>
                </a>
              ))}
            </div>
          </div>
        </div>
        <div className="flex justify-center">
          <div className="overflow-x-auto pb-4 scrollbar-hide max-w-full">
            <div className="flex space-x-6 md:space-x-12 px-4">
              {highlights.map((highlight, index) => (
                <a
                  key={index}
                  href={highlight.tiktokUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="w-64 h-[512px] relative flex-shrink-0"
                >
                  <div className="absolute inset-0 bg-[#171717] rounded-lg opacity-50"></div>
                  <div className="absolute inset-2 bg-[#222222] rounded-lg overflow-hidden">
                    <video
                      src={highlight.url}
                      autoPlay
                      loop
                      muted
                      playsInline
                      className="w-full h-full object-cover"
                      style={{ pointerEvents: 'none' }}
                    />
                  </div>
                </a>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SocialProof;